<template>
  <div class="Answer">
    <div class="banner">
      <img src="/subject/authenticationFT/banner.png" alt="">
    </div>
    <div class="box1">
      <div v-if="!StatusSuc">
        <input class="input1" v-model="form.Name" type="text" placeholder="请输入姓名">
        <input class="input2" v-model="form.CredentialsNum" type="text" placeholder="请输入身份证号">
        <img class="pushBtn" src="/subject/authenticationFT/pushBtn.png" alt=""  @click="openRedPacket">
      </div>
      <div v-else>
        <img class="imgSuccess" src="/subject/authentication/success.png" />
        <p class="sucp">已完成认证并领取奖励</p>
      </div>
    </div>
    <div class="box2">
      <div>
        <img class="reward" src="/subject/authentication/reward.png" />
        <p  class="yyyp">
          完成资料即入账，无附加条件
          <br />
          提交后可继续交易，盈利即可提现
        </p>
        <img src="/subject/authenticationFT/btnTrade.png" alt="" width="70%"  @click="goTrade">
      </div>
    </div>
    <div class="box3">
      <img src="/subject/authenticationFT/box3.png" />
    </div>
  </div>
  
</template>

<script>
import { getAccountCompletion ,updateUserIdInfo} from '../../../../api/info';
import { getUserByToken } from '../../../../api/components/loginDialog';
import { getSessionStorage } from '../classRoom/utils/tool';
import { getUrlParam } from '../../../../utils/tools'
export default {
  name: 'authentication',
  data() {
    return {
      loginDialogShow: true,
      StatusSuc:false,
      form:{
        Name:'',
        CredentialsNum:''
      },
      navBarFixed: false,
      // 用户账号
      sessionAccount: null,
     
      scroll: '',
      ttoken:''
    }
  },
  created() {
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow', true);
    var token_subject = ''
    if (getUrlParam('token_subject')) {
      token_subject = getUrlParam('token_subject')
      this.ttoken = getUrlParam('token_subject')
      getUserByToken(token_subject).then(res => {
        window.sessionStorage.setItem('account', res.data.Account)
        this.init()
      })
    } else if (getUrlParam('accessKey')) {
      token_subject = getUrlParam('accessKey')
        this.ttoken = getUrlParam('accessKey')
      getUserByToken(token_subject).then(res => {
        window.sessionStorage.setItem('account', res.data.Account)
        this.init()
      })
    } else if (window.localStorage.getItem('token_subject')) {
      token_subject = window.localStorage.getItem('token_subject')
      this.ttoken = window.localStorage.getItem('token_subject')
      getUserByToken(token_subject).then(res => {
        window.sessionStorage.setItem('account', res.data.Account)
        this.init()
      })
    } else {
      this.init()
    }
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    goTrade(){
      if(this.StatusSuc===false){
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        
      }else{
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isAndroid){
          window.android.gotoTrade();
        }else if(isiOS){
          window.webkit.messageHandlers.gotoTrade.postMessage("1")
        }
      }
    },
    openRedPacket(){
      if(this.sessionAccount===null){
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isAndroid){
          window.android.gotoLogin();
        }else if(isiOS){
          window.webkit.messageHandlers.gotoLogin.postMessage("1")
        }
      }else{
        const params ={
          Account :this.sessionAccount,
          Name : this.form.Name,
          CredentialsNum: this.form.CredentialsNum
        }
        updateUserIdInfo(this.ttoken,params).then(res=>{
          if(res.ok===0){
            this.$message.error(res.msg)
          }else if(res.ok===1){
            this.$message.success(res.msg)
            this.StatusSuc = true;
          }
        })
      }
    },
    init() {
      // 登录后再开始逻辑处理
      this.sessionAccount = getSessionStorage('account');
      if(this.sessionAccount===null){
        //this.loginDialogShow = true
      }else{
          // 查询认证状态
        getAccountCompletion(this.ttoken).then(res => {
          if(res.data.IsBasicAuthentication===2){
            this.StatusSuc = true;
          }
          
        })
      }
    },
   
   
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.Answer {
  width: 100%;
  height: auto;
  background: #B7111A;
  padding-bottom: 50px;
  .banner{
    img{
      width:100%;
    }
  }
  .box1{
    width: 97%;
    background: url(/subject/authenticationFT/box1.png) no-repeat;
    background-size: 100% 100%;
    padding: 8.2rem 0 2rem;
    margin-top: -4rem;
    position: relative;
    .tit{
      display: block;
      margin: 0 auto;
      width: 180px;
      height: 30px;
    }
    .imgSuccess{
      width: 132px;
      display: block;
      margin: 0 auto;
      height: 112px;
      margin-bottom: 14px;
      padding-left: 3%;
    }
    .sucp{
      color:#fe842b;
      text-align: center;
      font-weight: bold;
      padding-left: 3%;
    }
    .input1{
      color: #000000;
      width: 76%;
      border: 1px solid #ece6e6;
      height: 38px;
      box-sizing: border-box;
      display: block;
      margin:0 auto;
      margin-bottom: 20px;
      padding-left: 40px;
      background-size: 15px 15px;
      background-color: #F7F7F7;
      background-image: url(/subject/authenticationFT/icon1.png);
      background-repeat: no-repeat;
      background-position: 10px center;
      border-radius: 8px;
      font-size: 14px;
      
    }
     .input2{
      color: #000000;
      width: 76%;
      border: 1px solid #ece6e6;
      height: 38px;
      box-sizing: border-box;
      display: block;
      margin:0 auto;
      margin-bottom: 20px;
      padding-left: 40px;
      background-size: 17px 14px;
      background-color: #F7F7F7;
      background-image: url(/subject/authenticationFT/icon2.png);
      background-repeat: no-repeat;
      background-position: 10px center;
      border-radius: 8px;
      font-size: 14px;
    }
    .pushBtn{
      width: 70%;
      margin: auto;
      display: block;
    }
  }
  .box2{
    width: 94%;
    position: relative;
    margin: 30px auto;
    text-align: center;
    padding: 30px 0;
    background-size: 100% 100%;
    background-image: url(/subject/authenticationFT/box2.png);
    background-position: center center;
    .reward{
      display: block;
      margin: 0 auto;
      width:284px;
      height:85px;
    }
    .yyyp{
      color: #C18248;
      font-size: 13px;
      margin: 15px 0;
    }
  }
  .box3{
    width: 94%;
    margin:0 auto;
    margin-top: 20px;
    
    img {
      width: 100%;
    }
    
  }
}
</style>